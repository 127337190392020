<template>
  <b-row>
    <h2 class="pl-1">{{ $t("roles_permission.titles.users") }}</h2>

    <!--  BEFORE TABLE  -->
    <div class="d-flex justify-content-between col-12">
      <b-col md="4" class="my-1 px-0">
        <b-form-group class="mb-0">
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="searchQuery"
              type="search"
              :placeholder="$t('reusable.search_placeholder')"
            />
            <b-input-group-append>
              <b-button :disabled="!searchQuery" @click="searchQuery = ''">
                {{ $t("reusable.clear_btn") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!--   CREATE   -->
      <div v-if="createPermission" class="my-1 float-right">
        <modal-button
          ripple="rgba(113, 102, 240, 0.15)"
          size="lg"
          name="modal-main"
          :modal-title="$t('reusable.create')"
          :modal-button="$t('reusable.create')"
          @btn-emit="createUser"
          @open-emit="clearInputs"
          variant="success"
          ref="create-modal-button"
        >
          <template v-slot:button @click="createUser">{{
            $t("reusable.create")
          }}</template>

          <template v-slot:modal-body>
            <ValidationObserver ref="validation-observer">
              <div class="d-flex flex-wrap" style="gap: 3rem">
                <!--  USER  -->

                <div class="col-12 card__box-shadow">
                  <div class="row">
                    <b-col cols="6">
                      <ValidationProvider
                        :name="$t('name')"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-group :label="$t('name')" label-for="name">
                          <b-form-input
                            v-model="user.name"
                            id="name"
                            size="lg"
                            :placeholder="$t('name')"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <b-col cols="6">
                      <ValidationProvider
                        :name="$t('username')"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-group
                          :label="$t('username')"
                          label-for="username"
                        >
                          <b-form-input
                            v-model="user.username"
                            id="username"
                            size="lg"
                            :placeholder="$t('username')"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </div>
                </div>

                <!--  CONTACT  -->

                <div class="col-12 card__box-shadow">
                  <div class="row">
                    <b-col cols="4">
                      <ValidationProvider
                        :name="$t('job')"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-group :label="$t('job')" label-for="job">
                          <b-form-input
                            v-model="user.position"
                            id="job"
                            size="lg"
                            :placeholder="$t('job')"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <b-col cols="4">
                      <ValidationProvider
                        :name="$t('phone')"
                        rules="required|max:15"
                        v-slot="{ errors }"
                      >
                        <b-form-group :label="$t('phone')" label-for="phone">
                          <b-form-input
                            v-model="user.phone"
                            id="phone"
                            size="lg"
                            :placeholder="$t('phone_placeholder')"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <b-col cols="4">
                      <ValidationProvider
                        :name="$t('role')"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <infinity-scroll-select
                          class="mb-1"
                          v-model="user.role"
                          apiUrl="role.getRoleList"
                          :label="$t('role')"
                        />
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <b-col cols="4">
                      <ValidationProvider
                        :name="$t('password')"
                        rules="required|min:8"
                        v-slot="{ errors }"
                      >
                        <b-form-group
                          :label="$t('password')"
                          label-for="password"
                        >
                          <b-form-input
                            v-model="user.password"
                            id="password"
                            autocomplete="off"
                            type="password"
                            size="lg"
                            :placeholder="$t('password')"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <b-col cols="4">
                      <ValidationProvider
                        :name="$t('verified')"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-group
                          :label="$t('verified')"
                          label-for="verified"
                        >
                          <b-form-checkbox
                            switch
                            v-model="user.is_active"
                            id="verified"
                            :label="$t('verified')"
                            :placeholder="$t('verified')"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </template>
        </modal-button>
      </div>
    </div>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        sort-icon-left
        :busy="isBusy"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
      >
        <template v-slot:head()="data">
          {{ $t(`${data.label}`) }}
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">{{ $t("loading") }}</strong>
          </div>
        </template>

        <template #cell(role)="data">
          {{ data.item.role.name }}
        </template>

        <template #cell(crud_row)="data">
          <div class="d-flex float-right">
            <!--   EDIT   -->
            <modal-button
              v-if="editPermission"
              class="crud__btn"
              ripple="rgba(113, 102, 240, 0.15)"
              size="lg"
              @btn-emit="updateUserData(data.item.id, data.item)"
              @open-emit="getOneUser(data.item.id)"
              @close-emit="clearInputs"
              :name="`modal-edit-${data.item.id}`"
              :modal-button="$t('reusable.save_btn')"
              :id="data.item.id"
              :modal-title="$t('reusable.modal_edit_btn')"
              variant="flat-warning"
              button-variant="warning"
              ref="update-modal-button"
            >
              <template v-slot:button>
                <feather-icon icon="Edit2Icon" size="21" />
              </template>

              <template v-slot:modal-body>
                <ValidationObserver ref="validation-observer">
                  <div class="d-flex flex-wrap" style="gap: 3rem">
                    <!--  USER  -->

                    <div class="col-12 card__box-shadow">
                      <div class="row">
                        <b-col cols="6">
                          <ValidationProvider
                            :name="$t('name')"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <b-form-group :label="$t('name')" label-for="name">
                              <b-form-input
                                v-model="user.name"
                                id="name"
                                size="lg"
                                :placeholder="$t('name')"
                              />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-col>

                        <b-col cols="6">
                          <ValidationProvider
                            :name="$t('username')"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <b-form-group
                              :label="$t('username')"
                              label-for="username"
                            >
                              <b-form-input
                                v-model="user.username"
                                id="username"
                                size="lg"
                                :placeholder="$t('username')"
                              />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-col>
                      </div>
                    </div>

                    <!--  CONTACT  -->

                    <div class="col-12 card__box-shadow">
                      <div class="row">
                        <b-col cols="4">
                          <ValidationProvider
                            :name="$t('job')"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <b-form-group :label="$t('job')" label-for="job">
                              <b-form-input
                                v-model="user.position"
                                id="job"
                                size="lg"
                                :placeholder="$t('job')"
                              />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-col>

                        <b-col cols="4">
                          <ValidationProvider
                            :name="$t('phone')"
                            rules="required|max:15"
                            v-slot="{ errors }"
                          >
                            <b-form-group
                              :label="$t('phone')"
                              label-for="phone"
                            >
                              <b-form-input
                                v-model="user.phone"
                                id="phone"
                                size="lg"
                                :placeholder="$t('phone_placeholder')"
                              />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-col>

                        <b-col cols="4">
                          <ValidationProvider
                            :name="$t('role')"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <infinity-scroll-select
                              class="mb-1"
                              v-model="user.role"
                              apiUrl="role.getRoleList"
                              :label="$t('role')"
                            />
                            <span class="validation__red">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-col>

                        <b-col cols="4">
                          <ValidationProvider
                            :name="$t('password')"
                            rules="required|min:8"
                            v-slot="{ errors }"
                          >
                            <b-form-group
                              :label="$t('password')"
                              label-for="password"
                            >
                              <b-form-input
                                v-model="user.password"
                                id="password"
                                autocomplete="off"
                                type="password"
                                size="lg"
                                :placeholder="$t('password')"
                              />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-col>

                        <b-col cols="4">
                          <ValidationProvider
                            :name="$t('verified')"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <b-form-group
                              :label="$t('verified')"
                              label-for="verified"
                            >
                              <b-form-checkbox
                                switch
                                v-model="user.is_active"
                                id="verified"
                                :label="$t('verified')"
                                :placeholder="$t('verified')"
                              />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-col>
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </template>
            </modal-button>

            <!--   DELETE   -->
            <modal-button
              v-if="deletePermission"
              class="crud__btn"
              ripple="rgba(113, 102, 240, 0.15)"
              size="sm"
              :name="`modal-delete-${data.item.id}`"
              :modal-button="$t('reusable.modal_delete_btn')"
              :modal-title="$t('reusable.modal_delete_btn')"
              variant="flat-danger"
              :id="data.item.id"
              ref="delete-modal"
              @btn-emit="deleteUser"
            >
              <template v-slot:button>
                <feather-icon icon="Trash2Icon" size="21" />
              </template>

              <template v-slot:modal-body>
                <div class="my-2">
                  {{ $t("modal.user_delete") }}
                </div>
              </template>
            </modal-button>
          </div>
        </template>
      </b-table>
    </b-col>

    <!--  PAGINATION  -->
    <b-col cols="12">
      <b-pagination
        v-if="showPagination"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        align="center"
        size="sm"
        class="mt-0"
      ></b-pagination>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BSpinner,
  BCard,
  BFormTextarea,
} from "bootstrap-vue";
import ModalButton from "@/views/ui/modals/ModalButton";
import api from "@/services/api";
import Ripple from "vue-ripple-directive";
import ToastNotification from "@core/components/toastification/ToastNotification.vue";
import InfinityScrollSelect from "@/views/ui/infinity-scroll/InfinityScrollSelect";
import RolesPermission from "@/permission/roles";
import UsersPermission from "@/permission/users";
// import {debounce} from "@/util/helper";
import { provide, ref, watch } from "@vue/composition-api";
import route from "vue-router";

export default {
  name: "Users",
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    BCard,
    BFormTextarea,
    ModalButton,
    ToastNotification,
    InfinityScrollSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: {
        name: null,
        username: null,
        password: null,
        is_active: false,
        position: null,
        role: null,
        phone: null,
      },
      isBusy: false,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      searchQuery: this.$route.query.search,
      filterOn: [],
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "name",
          label: "name",
        },
        {
          key: "username",
          label: "username",
        },
        {
          key: "position",
          label: "job",
        },
        {
          key: "role",
          label: "role",
        },
        {
          key: "phone",
          label: "phone",
        },
        {
          key: "crud_row",
          label: " ",
        },
      ],
      items: [],
      loading: false,
      pagination: {
        current: 1,
        perPage: 20,
      },
      createPermission: UsersPermission.getUsersCreatePermission(),
      editPermission: UsersPermission.getUsersEditPermission(),
      deletePermission: UsersPermission.getUsersDeletePermission(),
    };
  },
  watch: {
    "$route.query": {
      handler(query) {
        this.pagination.current_page = query.page;
        this.getUsersList();
      },
      deep: true,
    },
    "pagination.current": {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        });
      },
    },
    searchQuery: {
      handler(value) {
        if (value && value.length > 2) {
          this.replaceRouter({
            ...this.query,
            search: value,
          });
        } else {
          const hasSearchField = this.query.hasOwnProperty("search");
          if (hasSearchField) {
            delete this.query["search"];
            this.replaceRouter({
              ...this.query,
            });
          }
        }
      },
    },
  },

  async created() {
    await this.getUsersList();
  },

  computed: {
    query() {
      return Object.assign({}, this.$route.query);
    },

    hasItems() {
      return this.items.length > 0;
    },

    showPagination() {
      return this.hasItems && !this.isBusy;
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
  },

  methods: {
    fullName(item) {
      if (item && item.first_name && item.last_name && item.second_name) {
        return item.first_name + " " + item.last_name + " " + item.second_name;
      }
    },
    showToast(variant, text, icon) {
      this.$toast({
        component: ToastNotification,
        props: {
          title: text,
          icon: icon,
          variant,
        },
      });
    },

    replaceRouter(query) {
      this.$router.replace({ query }).catch(() => {});
    },

    clearInputs() {
      this.user = {
        name: null,
        username: null,
        password: null,
        is_active: false,
        position: null,
        role: null,
        phone: null,
      };
    },

    async getUsersList() {
      this.isBusy = true;

      await api.users
        .getUsersList(this.query)
        .then((res) => {
          this.items = res.data.data.data;
          this.pagination = res.data.data;
        })
        .catch((error) => {
          if (error.response.data && error.response.data.errors) {
            let errors = [];
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              errors.push(value[0]);
            }
            errors.join(", ");
            this.showToast("danger", errors[0], "XIcon");
          } else if (error.response.data && error.response.data.message) {
            this.showToast("danger", error.response.data.message, "XIcon");
          } else {
            this.showToast("danger", "Что-то пошло не так!", "XIcon");
          }
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    getOneUser(id) {
      this.loading = true;
      api.users
        .getUser(id)
        .then((res) => {
          this.user = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async createUser() {
      const isValid = await this.$refs["validation-observer"].validate();
      if (isValid) {
        const { name, role, username, password, is_active, position, phone } =
          this.user;
        const data = {
          name,
          username,
          is_active,
          position,
          password,
          phone,
          role_id: role.id,
        };
        await api.users
          .createUser(data)
          .then(() => {
            this.$refs["create-modal-button"].closeModal();
            this.showToast("success", "Успешно создано!", "CheckIcon");
            this.getUsersList();
          })
          .catch((error) => {
            if (error.response.data && error.response.data.errors) {
              let errors = [];
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                errors.push(value[0]);
              }
              errors.join(", ");
              this.showToast("danger", errors[0], "XIcon");
            } else if (error.response.data && error.response.data.message) {
              this.showToast("danger", error.response.data.message, "XIcon");
            } else {
              this.showToast("danger", "Что-то пошло не так!", "XIcon");
            }
          });
      }
    },

    async updateUserData(id) {
      const isValid = await this.$refs["validation-observer"].validate();
      if (isValid) {
        const { name, role, username, password, is_active, position, phone } =
          this.user;
        const data = {
          name,
          username,
          is_active,
          position,
          password,
          phone,
          role_id: role.id,
        };
        api.users
          .updateUser(id, data)
          .then(() => {
            this.$refs["update-modal-button"].closeModal();
            this.getUsersList();
            this.clearInputs();
            this.showToast("success", "Успешно обновлено!", "CheckIcon");
          })
          .catch((error) => {
            if (error.response.data && error.response.data.errors) {
              let errors = [];
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                errors.push(value[0]);
              }
              errors.join(", ");
              this.showToast("danger", errors[0], "XIcon");
            } else if (error.response.data && error.response.data.message) {
              this.showToast("danger", error.response.data.message, "XIcon");
            } else {
              this.showToast("danger", "Что-то пошло не так!", "XIcon");
            }
          })
          .finally(() => {});
      }
    },

    deleteUser(id) {
      api.users
        .deleteUser(id)
        .then(() => {
          this.$refs["delete-modal"].closeModal();
          this.showToast("success", "Успешно удалено!", "CheckIcon");
        })
        .catch((error) => {
          if (error.response.data && error.response.data.errors) {
            let errors = [];
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              errors.push(value[0]);
            }
            errors.join(", ");
            this.showToast("danger", errors[0], "XIcon");
          } else if (error.response.data && error.response.data.message) {
            this.showToast("danger", error.response.data.message, "XIcon");
          } else {
            this.showToast("danger", "Что-то пошло не так!", "XIcon");
          }
        })
        .finally(() => {
          this.getUsersList();
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },

  // setup() {
  //     // console.log(ctx, 'ctx');
  //     // const router = useRouter()
  //     // const route = useRoute()
  //     console.log(route, 'route');
  //     const user = ref({
  //         name: null,
  //         username: null,
  //         password: null,
  //         is_active: false,
  //         position: null,
  //         role: null,
  //         phone: null,
  //     })
  //     const isBusy = ref(false)
  //     const sortBy = ref('')
  //     const sortDesc = ref(false)
  //     const sortDirection = ref('asc')
  //     const searchQuery = ref(route.query.search)
  //     const filterOn = []
  //     const fields = [
  //         {
  //             key: 'id',
  //             label: 'ID',
  //         },
  //         {
  //             key: 'name',
  //             label: 'name',
  //         },
  //         {
  //             key: 'username',
  //             label: 'username',
  //         },
  //         {
  //             key: 'position',
  //             label: 'job',
  //         },
  //         {
  //             key: 'role',
  //             label: 'role',
  //         },
  //         {
  //             key: 'phone',
  //             label: 'phone',
  //         },
  //         {
  //             key: 'crud_row',
  //             label: ' ',
  //         },
  //     ]
  //     const items = ref([])
  //     const loading = ref(false)
  //     const pagination = ref({
  //         current: 1,
  //         perPage: 20
  //     })
  //     const createPermission = UsersPermission.getUsersCreatePermission()
  //     const editPermission = UsersPermission.getUsersEditPermission()
  //     const deletePermission = UsersPermission.getUsersDeletePermission()
  //
  //
  //     return {
  //         createPermission,
  //         editPermission,
  //         deletePermission,
  //         pagination,
  //         loading,
  //         items,
  //         fields,
  //         filterOn,
  //         searchQuery,
  //         user,
  //         isBusy,
  //         sortBy,
  //         sortDesc,
  //         sortDirection
  //     }
  // }
};
</script>

<style lang="scss">
[dir] .dropdown-item {
  padding: 0;
}

.validation__red {
  color: red;
  font-size: 12px;
  display: block;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 6px;
}

.crud__btn .btn {
  padding: 0.6rem;
}
</style>
